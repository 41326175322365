import React, { CSSProperties, FC, PropsWithChildren } from 'react'

import { BrandColor } from 'pages/_serverRendered/CmsPage/types'
import cx from 'classnames'
import styles from './Card.module.scss'

export const colors = {
  white: '#fff',
  'moss-fine': '#f7fcf0',
  'moss-light': '#ecf3e9',
  'moss-regular': '#4a957a',
  'moss-bright': '#dcf836',
  'sonne-fine': '#fffbeb',
  'sonne-light': '#ffd66e',
  'sonne-regular': '#ffb800',
  'sonne-bright': '#ffe81d',
  'lapis-fine': '#edf5fa',
  'lapis-light': '#a2a7d3',
  'lapis-regular': '#484ec8',
  'lapis-bright': '#364af9',
  'tangerine-fine': '#fcf5f0',
  'tangerine-light': '#ffb7a1',
  'tangerine-regular': '#ff7043',
  'tangerine-bright': '#ff1850',
}

export const colorsToStyle: Record<string, CSSProperties> = {
  white: { backgroundColor: 'white' },
  'moss-fine': { backgroundColor: colors['moss-fine'] },
  'moss-light': { backgroundColor: colors['moss-light'] },
  'moss-regular': { backgroundColor: colors['moss-regular'], color: 'white' },
  'moss-bright': { backgroundColor: colors['moss-bright'] },
  'sonne-fine': { backgroundColor: colors['sonne-fine'] },
  'sonne-light': { backgroundColor: colors['sonne-light'] },
  'sonne-regular': { backgroundColor: colors['sonne-regular'] },
  'sonne-bright': { backgroundColor: colors['sonne-bright'] },
  'lapis-fine': { backgroundColor: colors['lapis-fine'] },
  'lapis-light': { backgroundColor: colors['lapis-light'] },
  'lapis-regular': { backgroundColor: colors['lapis-regular'], color: 'white' },
  'lapis-bright': { backgroundColor: colors['lapis-bright'], color: 'white' },
  'tangerine-fine': { backgroundColor: colors['tangerine-fine'] },
  'tangerine-light': { backgroundColor: colors['tangerine-light'] },
  'tangerine-regular': { backgroundColor: colors['tangerine-regular'] },
  'tangerine-bright': { backgroundColor: colors['tangerine-bright'], color: 'white' },
  transparent: {},
}

export interface CardProps {
  id?: string
  backgroundColor?: BrandColor
  className?: string
  style?: CSSProperties
}

const Card: FC<PropsWithChildren<CardProps>> = ({ backgroundColor, children, className, id, style }) => (
  <section
    id={id}
    className={cx(styles.card, className)}
    style={backgroundColor ? { ...style, ...colorsToStyle[backgroundColor] } : { ...style }}
  >
    {children}
  </section>
)

export default Card
