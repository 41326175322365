import React, { FC, useCallback } from 'react'
import cx from 'classnames'

import { useTrackClick } from '../../index'

import Arrow from 'svgs/arrow'

import styles from './styles.module.scss'

interface Props {
  className?: string
  text?: string
  targetId?: string
  trackingCode?: string
  trackingSection?: string
  trackingFeature?: string
  trackingFamily?: string
}
const NavLink: FC<Props> = ({
  className,
  text,
  targetId,
  trackingCode,
  trackingSection,
  trackingFeature,
  trackingFamily,
}) => {
  const trackClick = useTrackClick()
  const handleClick = useCallback(() => {
    // NavLink is always an internal-page fragment link, so we don't need
    // to wait for the tracking to finish, and can let the anchor function
    // normally.
    if (!trackingCode) return
    trackClick({
      code: trackingCode,
      section: trackingSection,
      feature: trackingFeature,
      family: trackingFamily,
    })
  }, [trackClick, trackingCode, trackingSection, trackingFeature, trackingFamily])

  // no render if no targetId.
  if (!targetId) {
    return null
  }

  return (
    <a className={cx(styles.navLink, className)} href={`#${targetId}`} onClick={handleClick}>
      {text && <span className={styles.text}>{text}</span>}
      <Arrow className={styles.arrow} />
    </a>
  )
}

export default NavLink
