import React, { FC, PropsWithChildren, ReactNode, useMemo } from 'react'
import cx from 'classnames'

import ChevronIcon from 'svgs/chevron-right'

import CoreDivider from 'components/Core/CoreDivider'

import styles from './styles.module.scss'

interface ListProps {
  className?: string
}

const InfoList: FC<PropsWithChildren<ListProps>> = ({ children, className }) => {
  return <ul className={cx(styles.list, className)}>{children}</ul>
}

interface ItemProps {
  className?: string

  divider?: boolean

  icon?: ReactNode

  onClick?: () => void
}

const InfoListItem: FC<PropsWithChildren<ItemProps>> = ({ children, className, divider = false, icon, onClick }) => {
  const content = useMemo(
    () => (
      <>
        <div className={styles.content}>
          <div className={styles.iconWrapper}>{icon}</div>
          <div className={styles.body}>{children}</div>
          {onClick && <ChevronIcon className={styles.action} />}
        </div>
        {divider && <CoreDivider.Indent />}
      </>
    ),
    [icon, divider, children, onClick]
  )
  return (
    <li className={cx(styles.listItem, className)}>
      {onClick ? (
        <button className={styles.actionContainer} onClick={onClick}>
          {content}
        </button>
      ) : (
        <div className={styles.actionContainer}>{content}</div>
      )}
    </li>
  )
}

export default InfoList
export { InfoListItem }
