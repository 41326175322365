import cx from 'classnames'
import React, { FC, useCallback } from 'react'

import AddressAutocomplete from 'components/MarketingSite/Header/AddressAutocomplete'

import Section from '../Section'

import styles from './styles.module.scss'

const FindPropertyCTA: FC<{ className?: string }> = ({ className }) => {
  const renderSubmitButton = useCallback(
    (onClick: (e: any) => void, disabled: boolean) => (
      <button className={styles.button} disabled={disabled} onClick={onClick}>
        {'View property'}
      </button>
    ),
    []
  )
  return (
    <Section className={cx(styles.findPropertyCta, className)}>
      <h2 className={styles.title}>{'See How Your Home Compares'}</h2>
      <div className={styles.addressSearch}>
        <AddressAutocomplete
          className={styles.input}
          placeholder="Enter an address"
          positionLabel="hero"
          renderSubmitButton={renderSubmitButton}
        />
      </div>
      <p className={styles.note}>
        {'We currently cover standalone, single family homes in all 50 states, but not in every county.'}
      </p>
      <p className={styles.note}>{'Check our coverage map for more details on your county'}</p>
    </Section>
  )
}

export default FindPropertyCTA
