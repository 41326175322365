import React, { FC, useCallback, useRef, useState } from 'react'
import cx from 'classnames'

import { useTrackClick } from '../../..'

import { CustomerTestimonial } from '../../../types'

import ArrowIcon from 'svgs/arrow'

import Testimonial from './Testimonial'

import styles from './styles.module.scss'

interface Props {
  className?: string
  testimonials: Array<CustomerTestimonial>

  trackingCode?: string
  trackingSection?: string
  trackingFamily?: string
}
const Testimonials: FC<Props> = ({ className, testimonials, trackingCode, trackingSection, trackingFamily }) => {
  const trackClick = useTrackClick()
  const scrollRef = useRef<HTMLDivElement>()
  const [rTestimonials] = useState([...testimonials.map((item, i) => ({ ...item, id: `${i}` }))])
  const scrollLock = useRef(false)

  const setScrollRef = useCallback((el: HTMLDivElement) => {
    if (el != scrollRef.current) {
      scrollRef.current = el
    }
  }, [])

  const handleScrollLeft = useCallback(() => {
    if (!scrollRef.current || scrollLock.current) return

    // We don't have to wait for it.
    trackClick({
      code: trackingCode,
      section: trackingSection,
      feature: 'left',
      family: trackingFamily,
    })

    scrollRef.current.scrollTo(scrollRef.current.scrollLeft - 480, 0)
  }, [trackClick, trackingCode, trackingSection, trackingFamily])

  const handleScrollRight = useCallback(() => {
    if (!scrollRef.current || scrollLock.current) return

    // We don't have to wait for it.
    trackClick({
      code: trackingCode,
      section: trackingSection,
      feature: 'right',
      family: trackingFamily,
    })

    scrollRef.current.scrollTo(scrollRef.current.scrollLeft + 480, 0)
  }, [trackClick, trackingCode, trackingSection, trackingFamily])

  return (
    <div className={cx(styles.testimonials, className)}>
      <button className={styles.scrollLeft} onClick={handleScrollLeft}>
        <ArrowIcon />
      </button>
      <div className={cx(styles.scroll)} ref={setScrollRef}>
        <ul>
          {rTestimonials.map((testimonial) => (
            <Testimonial key={testimonial.id} {...testimonial} />
          ))}
        </ul>
      </div>
      <button className={styles.scrollRight} onClick={handleScrollRight}>
        <ArrowIcon />
      </button>
    </div>
  )
}

export default Testimonials
