import Info from '@mui/icons-material/Info'
import CoreButton from 'components/Core/CoreButton'
import CoreInput from 'components/Core/CoreInput'
import TermsConsentMessage from 'components/TermsConsentMessage'
import AddressInput from 'pages/_serverRendered/EventSignup/AddressInput'
import React, { FC, PropsWithChildren, FormEvent, useState, useCallback, useEffect, ReactNode } from 'react'
import { SignUpResponse, UserStub } from 'recoil/user'
import { identify, track } from 'utils/analytics'
import { postFormData } from 'utils/fetch'
import { searchGooglePlaces } from 'apis/googlePlaces'
import cx from 'classnames'

export interface SignupFormUserStub {
  full_name: string
  phone_number: string
  email: string
}

class SignupError extends Error {
  errors: { [key: string]: string }

  constructor(errors: { [key: string]: string }) {
    super('Signup error')
    this.name = this.constructor.name
    this.errors = errors
    Error.captureStackTrace(this, this.constructor)
  }
}

interface SignupFormProps extends PropsWithChildren {
  className?: string
  header?: ReactNode
  onSuccess?: (user: SignupFormUserStub, creatingPropertyToken?: string) => void
  emailOptional?: boolean
  addressOptional?: boolean
}

const SignupForm: FC<SignupFormProps> = ({
  className,
  header,
  onSuccess,
  children,
  emailOptional,
  addressOptional,
}) => {
  const [formData, setFormData] = useState<FormData | null>(null)
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [fetching, setFetching] = useState(false)
  const [alert, setAlert] = useState<{ type: 'error' | 'success'; message: string } | null>(null)
  const [formKey, setFormKey] = useState(Date.now())
  const [placeId, setPlaceId] = useState<string>()

  const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setFormData(new FormData(e.target as HTMLFormElement))
  }, [])

  const handleAddressChange = useCallback(
    (prediction: { address: string; place_id: string }) => setPlaceId(prediction?.place_id),
    []
  )

  const trackNewUser = useCallback(
    async (user: UserStub) => {
      // call identify before tracking the signup event so that we have Realm's User Id already in place.
      await identify(user)

      const marketplace = placeId ? (await searchGooglePlaces({ placeId })).marketplace : false

      await track(
        'signup',
        {
          $email: user.email,
          $name: user.full_name,
          address: user.entered_address,
          via: 'form',
          signupDate: user.created_at,
          is_marketplace: marketplace,
        },
        {
          id: 24230,
          args: {
            orderId: `signup-${user.id}`,
            customerId: user.id,
            customerEmail: user.sha1_email,
          },
        }
      )
    },
    [placeId]
  )

  const registerNewLead = useCallback(
    async (formData) => {
      const response = await postFormData('/users/leads', formData)
      if (response.isError) {
        const rawErrors = response.jsonBody as { [key: string]: string[] }
        const newErrors = Object.keys(rawErrors).reduce(
          (memo, key) => {
            memo[key] = rawErrors[key].join(', ')
            return memo
          },
          {} as { [key: string]: string }
        )
        throw new SignupError(newErrors)
      }

      const user = response.jsonBody.user as SignupFormUserStub
      track('event-lead', { $email: user.email, $name: user.full_name })
      if (onSuccess) onSuccess(user, response.jsonBody.pollable_job_token)
    },
    [onSuccess]
  )

  const registerNewUser = useCallback(
    async (formData) => {
      const response = await postFormData('/users', formData)
      if (response.isError) {
        const rawErrors = response.jsonBody as { [key: string]: string[] }
        const newErrors = Object.keys(rawErrors).reduce(
          (memo, key) => {
            memo[key] = rawErrors[key].join(', ')
            return memo
          },
          {} as { [key: string]: string }
        )
        throw new SignupError(newErrors)
      }

      const user = (response.jsonBody as SignUpResponse).user
      trackNewUser(user)
      if (onSuccess) onSuccess(user, response.jsonBody.pollable_job_token)
    },
    [trackNewUser, onSuccess]
  )

  useEffect(() => {
    const _ = async () => {
      if (!formData) return
      if (fetching) return

      const email = formData.get('user[email]')?.toString()
      const mode = !email?.length && emailOptional ? 'lead' : 'user'

      try {
        setErrors({})
        setFetching(true)

        await (mode == 'lead' ? registerNewLead(formData) : registerNewUser(formData))

        setFormData(null)
        setFetching(false)
        setAlert({ type: 'success', message: 'User added!' })
        setFormKey(Date.now())
      } catch (e) {
        if (e instanceof SignupError) {
          setErrors(e.errors)
        } else {
          console.error(e)
          setAlert({ type: 'error', message: 'An error occurred' })
        }
        setFormData(null)
        setFetching(false)
      }
    }
    _()
  }, [emailOptional, fetching, formData, registerNewLead, registerNewUser])

  useEffect(() => {
    let timeout
    if (alert) timeout = setTimeout(() => setAlert(null), 5000)

    return () => clearTimeout(timeout)
  }, [alert])

  return (
    <form onSubmit={handleSubmit} className={className} key={formKey}>
      {header}
      {!!alert && (
        <div
          className={cx('tw-p-3 tw-flex tw-gap-2', {
            'tw-bg-red-100 tw-text-red-600': alert.type == 'error',
            'tw-bg-blue-100 tw-text-blue-600': alert.type == 'success',
          })}
        >
          <Info />
          <div>{alert.message}</div>
        </div>
      )}
      <AddressInput
        label="Address"
        name="user[entered_address]"
        hint={errors.entered_address}
        kind={errors.entered_address ? 'alert' : 'enabled'}
        onChange={handleAddressChange}
        required={!addressOptional}
      />
      <CoreInput.Text
        label="Full name"
        name="user[full_name]"
        hint={errors.full_name}
        kind={errors.full_name ? 'alert' : 'enabled'}
        required
      ></CoreInput.Text>
      <CoreInput.Text
        label="Email"
        type="email"
        name="user[email]"
        hint={errors.email}
        kind={errors.email ? 'alert' : 'enabled'}
        required={!emailOptional}
      ></CoreInput.Text>
      <CoreInput.Text
        type="tel"
        label="Phone"
        name="user[phone_number]"
        hint={errors.phone_number || 'We will never share this with third parties.'}
        kind={errors.phone_number ? 'alert' : 'enabled'}
      ></CoreInput.Text>
      <CoreInput.TextArea name="user[signup_message]" label="Anything else you want to share?"></CoreInput.TextArea>
      <input type="hidden" name="user[completed_profile]" value="true" />
      {children}
      <TermsConsentMessage className="tw-text-disabled-black tw-text-sm" />
      <CoreButton
        className="tw-w-full"
        text={fetching ? 'Submitting...' : 'Submit'}
        type="submit"
        disabled={fetching}
      />
    </form>
  )
}

export default SignupForm
