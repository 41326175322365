import dayjs from 'dayjs'
import { useCallback } from 'react'
import { DefaultValue, atom, selector, useResetRecoilState } from 'recoil'
import { UserStub } from 'recoil/user'
import { postJSON } from 'utils/fetch'

const sessionStorageEffect =
  (key, expirationInHours) =>
  ({ setSelf, onSet }) => {
    // Don't do this if we're SSR
    if (typeof window === 'undefined') return

    const savedValue = sessionStorage.getItem(key)

    const expKey = `${key}-exp`
    const timestamp = sessionStorage.getItem(expKey)
    const expired = !timestamp || dayjs() >= dayjs(timestamp).add(expirationInHours, 'hours')

    if (expired) {
      setSelf(new DefaultValue())
    } else if (savedValue != null) {
      setSelf(JSON.parse(savedValue))
    }

    onSet((newValue) => {
      if (newValue instanceof DefaultValue) {
        sessionStorage.removeItem(key)
        sessionStorage.removeItem(expKey)
      } else {
        sessionStorage.setItem(key, JSON.stringify(newValue))
        sessionStorage.setItem(expKey, dayjs().toISOString())
      }
    })
  }

export interface EventsAe {
  id: string
  name: string
  admin_user_id: number
  calendly_link: string | null
}

export interface EventsProps {
  events_team_members: string[] | null
  sales_events: EventsEvent[]
  webview: boolean
  calendly_url: string
  aes: EventsAe[]
  packages: EventsPackage[]
}

export type EventsMode = 'meeting' | 'deposit' | 'just-info'

export interface EventsPackage {
  id: number
  name: string
  price_in_dollars: number
}

interface PackagePurchaseRequest {
  package_id: number
  user_id: number
  custom_price_in_cents?: number
  admin_user_id: number
  property_id: number
}

interface PurchaseSuccess {
  isError: false
  redirect_url: string
}

interface PurchaseFailure {
  isError: true
  errors: Record<string, string[]>
}

interface EventsEvent {
  name: string
  event_type: 'home_show' | 'community_event' | 'conference' | null
  event_category:
    | 'real_estate_event'
    | 'home_show'
    | "farmer's_market"
    | 'community_event'
    | 'retail_popup'
    | 'sporting_event'
    | null
}

type PackagePurchaseResponse = PurchaseSuccess | PurchaseFailure

export const eventsSelectedPackageState = atom<EventsPackage | null>({
  key: 'eventsSelectedPackageState',
  default: null,
})

export const eventsSelectedPackagePriceInDollarsState = atom<number | null>({
  key: 'selectedPackagePriceInDollars',
  default: null,
})

export const eventsAddedUserState = atom<UserStub | null>({
  key: 'eventsAddedUserState',
  default: null,
})

export const eventsSelectedModeState = atom<string>({
  key: 'eventsSelectedModeState',
  default: '',
})

export const eventsCreatingPropertyTokenState = atom<string | null>({
  key: 'eventsCreatingPropertyTokenState',
  default: '',
})

export const eventsPropsState = atom<EventsProps | null>({
  key: 'eventsPropsState',
  default: null,
})

export const eventsSelectedAeIdState = atom<string>({
  key: 'eventsSelectedAeIdState',
  default: '',
  effects_UNSTABLE: [sessionStorageEffect('eventsSelectedAeIdState', 12)],
})

export const eventSelectedTeamMemberState = atom<string>({
  key: 'eventSelectedTeamMemberState',
  default: '',
  effects_UNSTABLE: [sessionStorageEffect('eventSelectedTeamMemberState', 12)],
})

export const eventsSelectedAeSelector = selector<EventsAe | null>({
  key: 'eventsSelectedAeSelector',
  get: ({ get }) => {
    const id = get(eventsSelectedAeIdState)
    if (!id) return null

    const props = get(eventsPropsState)
    if (!props) return null

    const { aes } = props
    if (!aes) return null

    return aes.find((x) => x.id.toString() == id) || null
  },
})

export const eventsSelectedMarketState = atom<string>({
  key: 'eventsSelectedMarketState',
  default: '',
  effects_UNSTABLE: [sessionStorageEffect('eventsSelectedMarketState', 12)],
})

export const purchasePackage = async (request: PackagePurchaseRequest): Promise<PackagePurchaseResponse> => {
  const response = await postJSON(`/api/v1/packages/${request.package_id}/purchase`, request as any)

  if (response.isError) {
    return { isError: true, errors: response.jsonBody }
  } else {
    return { isError: false, ...response.jsonBody }
  }
}

export const useResetEventOnboarding: () => () => void = () => {
  const resetAddedUser = useResetRecoilState(eventsAddedUserState)
  const resetSelectedMode = useResetRecoilState(eventsSelectedModeState)
  const resetSelectedPackage = useResetRecoilState(eventsSelectedPackageState)
  const resetSelectedPrice = useResetRecoilState(eventsSelectedPackagePriceInDollarsState)

  return useCallback(() => {
    resetAddedUser()
    resetSelectedMode()
    resetSelectedPackage()
    resetSelectedPrice()
  }, [resetAddedUser, resetSelectedMode, resetSelectedPackage, resetSelectedPrice])
}
