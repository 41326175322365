import React, { FC } from 'react'
import cx from 'classnames'

import { Hero as HeroType } from '../types'

import Background from './Background'
import Headline from './Headline'
import SubHeadline from './SubHeadline'
import NavLink from './NavLink'

import styles from './styles.module.scss'

// Parse int that returns undefined if we get NaN
function pInt(val?: string | null): number | undefined {
  const ret = parseInt(val as string)
  if (Number.isNaN(ret)) return undefined
  return ret
}

interface Props extends HeroType {
  className?: string
}
const Hero: FC<Props> = ({
  className,
  headline,
  headline_rotation,
  headline_rotation_speed,
  sub_headline,
  nav_link_text,
  nav_link_section_id,
  nav_link_tracking_code,
  nav_link_tracking_section,
  nav_link_tracking_feature,
  nav_link_tracking_family,
  background_images,
  background_images_rotation_velocity,
  background_images_rotation_drag,
}) => {
  return (
    <section className={cx(styles.hero, className)}>
      <Background
        className={styles.background}
        images={background_images}
        minSpeed={pInt(background_images_rotation_velocity)}
        drag={pInt(background_images_rotation_drag)}
        minWidth={700}
      />
      <div className={styles.content}>
        <Headline
          className={styles.headline}
          headline={headline}
          headlineRotationOptions={headline_rotation.map((item) => item.text)}
          headlineRotationSpeed={pInt(headline_rotation_speed)}
        />
        <SubHeadline className={styles.subHeadline} subHeadline={sub_headline} />
        <NavLink
          className={styles.navLink}
          text={nav_link_text}
          targetId={nav_link_section_id}
          trackingCode={nav_link_tracking_code}
          trackingSection={nav_link_tracking_section}
          trackingFeature={nav_link_tracking_feature}
          trackingFamily={nav_link_tracking_family}
        />
      </div>
    </section>
  )
}

export default Hero
