import { autocompleteGooglePlaces } from 'apis/googlePlaces'
import CoreInput, { CoreInputProps } from 'components/Core/CoreInput'
import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  FocusEvent,
  useState,
  KeyboardEvent,
} from 'react'
import { FloatingPortal, size, useDismiss, useFloating, useInteractions } from '@floating-ui/react'
import type { ElementProps } from '@floating-ui/react'
import cx from 'classnames'
import _ from 'lodash'

interface AddressInputProps extends Omit<CoreInputProps, 'value' | 'onChange'> {
  onChange?: (prediction: { address: string; place_id: string } | undefined) => void
}

const AddressInput: ForwardRefRenderFunction<HTMLInputElement, AddressInputProps> = (
  { name, onChange, onBlur, ...rest },
  ref
) => {
  const [query, setQuery] = useState<string>('')
  const [debouncedQuery, setDebouncedQuery] = useState<string>(query)
  const [predictions, setPredictions] = useState<{ address: string; place_id: string }[]>([])
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [selected, setSelected] = useState<{ address: string; place_id: string }>()
  const [referenceWidth, setReferenceWidth] = useState<number>()
  const [keyboardHover, setKeyboardHover] = useState<number>()

  const { context, x, y, refs, strategy, update } = useFloating({
    open: popoverOpen,
    onOpenChange: setPopoverOpen,
    placement: 'bottom',
    middleware: [
      size({
        apply({ rects }) {
          setReferenceWidth(rects.reference.width)
        },
      }),
    ],
  })

  const interactions = [useDismiss(context)].filter((e) => e) as ElementProps[]

  const { getReferenceProps, getFloatingProps } = useInteractions(interactions)

  const handleChange = useCallback((value: string) => {
    setQuery(value)
    setSelected(undefined)
  }, [])

  const handleFocus = useCallback(() => setPopoverOpen(true), [])

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      e.stopPropagation()
      e.preventDefault()

      if (!selected) setQuery('')
      onBlur?.call(e)
    },
    [selected, onBlur]
  )

  const handleSelectPrediction = useCallback(
    (prediction) => () => {
      setSelected(prediction)
      setQuery('')
    },
    []
  )

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (!predictions.length) return

      if (e.code == 'ArrowDown') {
        const next = keyboardHover === undefined ? 0 : Math.min(predictions.length - 1, keyboardHover + 1)
        setKeyboardHover(next)
      } else if (e.code == 'ArrowUp') {
        const next = keyboardHover === undefined ? undefined : Math.max(0, keyboardHover - 1)
        setKeyboardHover(next)
      } else if (e.code == 'Enter') {
        e.preventDefault()
        if (keyboardHover !== undefined) {
          handleSelectPrediction(predictions[keyboardHover])()
          setKeyboardHover(undefined)
        }
      }
    },
    [predictions, keyboardHover, handleSelectPrediction]
  )

  useEffect(() => {
    if (popoverOpen) update()
  }, [update, popoverOpen, referenceWidth])

  useEffect(() => {
    const update = _.debounce((value: string) => setDebouncedQuery(value), 200)
    update(query)
    return () => update.cancel()
  }, [query])

  useEffect(() => {
    if (!debouncedQuery.length) {
      return setPredictions([])
    }

    const _ = async () => {
      try {
        const results = await autocompleteGooglePlaces(debouncedQuery)
        setPredictions(results.items)
      } catch (e) {
        console.error(e)
      }
    }
    _()
  }, [debouncedQuery])

  useEffect(() => setPopoverOpen(predictions && !!predictions.length), [predictions])
  useEffect(() => onChange?.call(selected), [selected, onChange])

  useEffect(() => {
    if (selected) {
      setQuery('')
    }
  }, [selected])

  useEffect(() => {
    if (query.length) {
      setSelected(undefined)
    }
  }, [query.length])

  return (
    <>
      <input
        name={name}
        className={cx({ 'tw-font-bold': !!selected })}
        type="hidden"
        value={selected ? `place_id:${selected.place_id}` : ''}
      />
      <div {...getReferenceProps({ ref: refs.setReference })}>
        <CoreInput.Text
          {...rest}
          ref={ref}
          value={selected ? selected.address.split(', ').slice(0, -1).join(', ') : query}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
      </div>
      <FloatingPortal>
        {popoverOpen && (
          <div
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y ?? '',
                left: x ?? '',
                width: referenceWidth,
              },
              onMouseDown: (e) => {
                e.preventDefault() // Prevent the mouse down event from blurring the input
              },
            })}
          >
            <div className="tw-bg-white tw-rounded-xl tw-drop-shadow-lg">
              {predictions.map((prediction, i) => {
                const [line1, ...rest] = prediction.address.split(', ')
                const line2 = rest.join(', ')

                return (
                  <button
                    className={cx(
                      'tw-w-full tw-flex tw-flex-col tw-p-3 tw-gap-0.5 tw-text-md tw-bg-white hover:tw-bg-neutrals-100',
                      { '!tw-bg-neutrals-100': i == keyboardHover }
                    )}
                    key={`prediction_${i}`}
                    onClick={handleSelectPrediction(prediction)}
                  >
                    <div className="tw-font-semibold">{line1}</div>
                    <div className="tw-text-disabled-black">{line2}</div>
                  </button>
                )
              })}
            </div>
          </div>
        )}
      </FloatingPortal>
    </>
  )
}

export default forwardRef(AddressInput)
