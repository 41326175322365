import React, { FC, PropsWithChildren, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import type { RedirectProps } from 'react-router-dom'
import cx from 'classnames'

import CloseIcon from 'svgs/close'

import CoreLightbox from './CoreLightbox'
import CoreText from './CoreText'

import styles from './CoreSheetModal.module.scss'

interface Props {
  className?: string
  title?: string | ReactNode | (() => ReactNode)
  onClose?: (() => void) | RedirectProps
}

const CoreSheetModal: FC<PropsWithChildren<Props>> = ({ children, className, title = '', onClose }) => {
  return (
    <CoreLightbox className={cx(styles.sheetModal, className)} onClose={onClose} position="center">
      <header>
        {onClose &&
          (typeof onClose === 'function' ? (
            <button className={styles.close} onClick={onClose}>
              <CloseIcon />
            </button>
          ) : (
            <Link {...onClose}>
              <CloseIcon />
            </Link>
          ))}
        <CoreText.Paragraph size="l" weight="heavy" color="primaryBlack">
          {typeof title === 'function' ? title() : title}
        </CoreText.Paragraph>
      </header>
      <section>{children}</section>
    </CoreLightbox>
  )
}

export default CoreSheetModal
