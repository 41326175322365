import React, { FC, useMemo, useState, useCallback } from 'react'
import cx from 'classnames'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { prepare, trackClick as baseTrackClick } from 'utils/analyticsV2'

import ClockIcon from 'svgs/access-time-filled'

import CoreText from 'components/Core/CoreText'
import CoreInfoLabel from 'components/Core/CoreInfoLabel'

import Calendar, { CalendarMarks, setMark } from './Calendar'
import Period, { PERIODS } from './Period'
import { Block } from './Time'

import styles from './styles.module.scss'

export type { Block } from './Time'

dayjs.extend(utc)
dayjs.extend(timezone)

const trackClick = prepare(
  {
    family: 'vendor-site-visit',
    screen: 'unscheduled', // This will have to change if we reuse this component!
    section: 'availability',
  },
  baseTrackClick
)

interface Props {
  availabilities: Array<Block>
  selected?: Block
  eventDuration: number
  disabled: boolean
  onChange: (block: Block) => void
}
const Availabilities: FC<Props> = ({ availabilities, selected, eventDuration, disabled, onChange }) => {
  const [selectedDate, setSelectedDate] = useState(() => {
    if (availabilities.length > 0) {
      return availabilities[0].start
    }
    return new Date()
  })

  const handleTrackAttempted = useCallback((code: string, flexfield_1: any) => {
    trackClick({ code, flexfield_1 })
  }, [])

  const firstAvailability = useMemo(() => {
    if (availabilities.length > 0) {
      return availabilities[0].start
    }
  }, [availabilities])

  const todaysAvailabilities = useMemo(() => {
    return availabilities.filter((block) => dayjs(selectedDate).isSame(block.start, 'day'))
  }, [selectedDate, availabilities])

  const marks = useMemo(() => {
    return availabilities.reduce((marks, block) => {
      return setMark(marks, dayjs(block.start), 'default')
    }, {} as CalendarMarks)
  }, [availabilities])

  const tzName = useMemo(() => {
    return (
      new Intl.DateTimeFormat('en-US', { timeZoneName: 'long' })
        .formatToParts(selectedDate)
        .find((part) => part.type == 'timeZoneName')?.value || ''
    )
  }, [selectedDate])

  return (
    <div className={styles.availabilities}>
      <Calendar
        className={styles.calendar}
        selected={selectedDate}
        onSelectionChange={setSelectedDate}
        marks={marks}
        onTrackAttempt={handleTrackAttempted}
      />
      <div className={styles.selectedDate}>
        <div className={styles.mainLine}>
          <CoreText.Paragraph>
            {selectedDate.toLocaleDateString('en-US', {
              weekday: 'short',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </CoreText.Paragraph>
          {firstAvailability && dayjs(firstAvailability).isSame(dayjs(selectedDate), 'day') && (
            <CoreInfoLabel className={styles.firstAvailability} text={`1st Availability`} />
          )}
        </div>
        <CoreText.Paragraph
          className={cx({ [styles.notPacific]: !['Pacific Standard Time', 'Pacific Daylight Time'].includes(tzName) })}
          weight="light"
          color="tertiaryBlack"
        >
          {tzName}
        </CoreText.Paragraph>
      </div>
      {todaysAvailabilities.length == 0 ? (
        <CoreText.Paragraph
          className={styles.noTimes}
          color="disabledBlack"
          weight="light"
        >{`No available times for the selected date`}</CoreText.Paragraph>
      ) : (
        <>
          <CoreInfoLabel
            className={styles.visitLength}
            text={`Site visit length: ${eventDuration}mins`}
            icon={<ClockIcon />}
          />
          <ol>
            {PERIODS.map((period) => (
              <Period
                key={period}
                period={period}
                availabilities={todaysAvailabilities}
                selected={selected}
                disabled={disabled}
                onChange={onChange}
              />
            ))}
          </ol>
        </>
      )}
    </div>
  )
}

export default Availabilities
