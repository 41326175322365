import React, { FC, useCallback } from 'react'

import EventAvailableIcon from 'svgs/event-available'
import DateIcon from 'svgs/calendar'
import TimeIcon from 'svgs/clock'
import Spinner from 'svgs/spinner'

import CoreModal from 'components/Core/CoreModal'
import CoreText from 'components/Core/CoreText'
import CoreButton from 'components/Core/CoreButton'

import InfoList, { InfoListItem } from './components/InfoList'

import styles from './ConfirmBookingModal.module.scss'

interface Props {
  startTime: Date
  endTime: Date
  submitting: boolean
  onClose?: () => void
  onConfirm?: () => void
}

const ConfirmBookingModal: FC<Props> = ({ startTime, endTime, submitting, onClose, onConfirm }) => {
  const renderButtons = useCallback(
    () => (
      <>
        <CoreButton
          text="Book site visit"
          kind="primary"
          disabled={submitting}
          icon={submitting ? <Spinner strokeWidth={16} /> : undefined}
          onClick={onConfirm}
        />
        <CoreButton text="Cancel" kind="secondary" disabled={submitting} onClick={onClose} />
      </>
    ),
    [submitting, onConfirm, onClose]
  )
  return (
    <CoreModal
      className={styles.confirmBookingModal}
      title="Confirm Site Visit Booking"
      onClose={onClose}
      buttons={renderButtons}
      icon={<EventAvailableIcon />}
      kind="success"
    >
      <InfoList className={styles.bookingInfo}>
        <InfoListItem icon={<DateIcon />}>
          <CoreText.Paragraph size="m" color="primaryBlack">
            {startTime.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })}
          </CoreText.Paragraph>
        </InfoListItem>
        <InfoListItem icon={<TimeIcon />}>
          <CoreText.Paragraph size="m" color="primaryBlack">
            {`${startTime.toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })} - ${endTime.toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}`}
          </CoreText.Paragraph>
          <CoreText.Paragraph size="m" weight="light" color="primaryBlack">
            {
              new Intl.DateTimeFormat('en-US', { timeZoneName: 'long' })
                .formatToParts(startTime)
                .find((part) => part.type == 'timeZoneName')?.value
            }
          </CoreText.Paragraph>
        </InfoListItem>
      </InfoList>
    </CoreModal>
  )
}

export default ConfirmBookingModal
