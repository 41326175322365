import cx from 'classnames'
import React, { FC, PropsWithChildren, useCallback } from 'react'

import styles from './styles.module.scss'

type AccordionItemProps = {
  index: number
  selectedIndices: number[]
  title: string
  toggleIndex: (index?: number) => void
}

const AccordionItem: FC<PropsWithChildren<AccordionItemProps>> = ({
  children,
  index,
  selectedIndices,
  title,
  toggleIndex,
}) => {
  const handleClick = useCallback(() => {
    toggleIndex(index)
  }, [toggleIndex, index])

  return (
    <section className={cx(styles.itemContainer, { [styles.selected]: selectedIndices.includes(index) })}>
      <button className={styles.button} onClick={handleClick}>
        {title}
      </button>
      <div className={styles.item}>{children}</div>
    </section>
  )
}

export default AccordionItem
