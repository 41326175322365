import React, { FC, useState, useCallback, useEffect, useMemo } from 'react'

import { getJSON } from 'utils/fetch'
import { prepare, trackClick as baseTrackClick, prepareName, TrackBase } from 'utils/analyticsV2'

import { Calendar, Event, UEvent } from './types'
import { schedulerPatchJSON } from './utils'

import CoreText from 'components/Core/CoreText'
import CoreButton from 'components/Core/CoreButton'

import Expired from './Expired'
import Unavailable from './Unavailable'
import { Block } from './Availabilities'
import FailureModal from './FailureModal'
import HouseholdDetails from './HouseholdDetails'

import AvailabilitySelection from './AvailabilitySelection'

import styles from './Reschedule.module.scss'

const trackClick = prepare(
  {
    family: 'vendor-site-visit',
    screen: 'reschedule',
  },
  baseTrackClick
)
const trackError = prepareName<TrackBase>('state-event', {
  code: 'error',
  family: 'vendor-site-visit',
  screen: 'reschedule',
  feature: 'rescheduling',
})

interface Props {
  calendar: Calendar
  event: Event
  onCalendarChange: React.Dispatch<React.SetStateAction<Calendar>>
  onBack: () => void
}

const Unscheduled: FC<Props> = ({ calendar, event, onCalendarChange, onBack }) => {
  const [submitting, setSubmitting] = useState(false)
  const [timer, setTimer] = useState(0)
  const [time, setTime] = useState<Block | null>(null)
  const [scheduleError, setScheduleError] = useState(false)
  const [timeUnavailableModal, setTimeUnavailableModal] = useState(false)

  useEffect(() => {
    // Update our view every 30 seconds.
    const interval = setInterval(() => setTimer((prev) => prev + 1), 30000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const processSubmission = useCallback(
    async (time: Block) => {
      setSubmitting(true)

      const body: UEvent = {
        start_time: time.start.toISOString(),
        end_time: time.end.toISOString(),
      }

      try {
        const response = await schedulerPatchJSON(
          `/vendor_site_visits/${calendar.id}/events/${event.id}`,
          (body as unknown) as Record<string, unknown>,
          calendar.id,
          onCalendarChange
        )
        if (response.code != 200) {
          trackError({
            flexfield_1: {
              errorType: 'http',
              status: response.code,
              code: response.jsonBody?.code,
              time: time,
              canceled: false,
            },
          })
        }
        if (response.code == 400 && response.jsonBody?.code == 'time_unavailable') {
          // Best effort only. Goal here is to receive updated calendar times
          // to display the most accurate info.
          try {
            // Refresh our calendar info.
            const cResponse = await getJSON<Calendar>(`/vendor_site_visits/${calendar.id}`)
            onCalendarChange((prev) => ({ ...prev, ...cResponse }))
          } catch {
            /* Ignore */
          }
          setTimeUnavailableModal(true)
        } else if (response.code != 200) {
          setScheduleError(true)
        } else {
          const event: Event = response.jsonBody
          onCalendarChange((prev) => ({ ...prev, event: { ...event } }))

          // Best effort only. Goal here is to receive updated customer contact
          // info.
          try {
            // Refresh our calendar info.
            const cResponse = await getJSON<Calendar>(`/vendor_site_visits/${calendar.id}?event_id=${event.id}`)
            onCalendarChange((prev) => ({ ...prev, ...cResponse }))
          } catch {
            /* Ignore */
          }

          // Then go back to the scheduled screen.
          onBack()
        }
      } catch (e) {
        trackError({
          flexfield_1: {
            errorType: 'exception',
            message: e.toString(),
            time: time,
            canceled: false,
          },
        })
        setScheduleError(true)
      }

      setSubmitting(false)
      return true
    },
    [onCalendarChange, calendar, event, onBack]
  )

  const handleTimeSelected = useCallback(
    async (time: Block) => {
      setTime(time)
      await processSubmission(time)
    },
    [processSubmission]
  )

  const handleScheduleErrorClose = useCallback(() => {
    trackClick({ code: 'close', section: 'schedule-error' })
    setScheduleError(false)
  }, [])
  const handleTimeUnavailableModalClose = useCallback(() => {
    trackClick({ code: 'close', section: 'time-unavailable-error' })
    setTimeUnavailableModal(false)
  }, [])

  const handleClose = useCallback(() => {
    trackClick({ code: 'close', section: 'reschedule' })
    onBack()
  }, [onBack])

  const availabilities = useMemo(() => {
    // eventDurationSize
    const eventDurationSize = calendar.event_duration * 60 * 1000
    // Increment size will be 30m, or if our event duration is smaller, that.
    const incrementSize = Math.max(30 * 60 * 1000, calendar.event_duration)
    // Split into even length time blocks.
    // Only allow availabilities to start and end at the top of the increment size.
    return calendar.availabilities.reduce((newAvail, block) => {
      const origStart = new Date(block.time.start)
      const origEnd = new Date(block.time.end)

      const start = Math.ceil(origStart.getTime() / incrementSize) * incrementSize
      const lastStart = origEnd.getTime() - eventDurationSize
      for (let x = start; x <= lastStart; x += incrementSize) {
        newAvail.push({ start: new Date(x), end: new Date(x + eventDurationSize) })
      }
      return newAvail
    }, [] as Array<Block>)
  }, [calendar])

  const status = useMemo(() => {
    const now = Date.now()
    const booking_due_date = new Date(calendar.booking_due_date || 0).getTime()
    if (booking_due_date <= now) return 'expired'
    else if (availabilities.length == 0) return 'unavailable'
    return 'reschedule'

    // We want this to recalculate each time timer changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendar, availabilities, timer])

  if (status == 'expired') return <Expired calendar={calendar} />
  else if (status == 'unavailable') return <Unavailable calendar={calendar} />

  return (
    <div className={styles.reschedule}>
      <header>
        <CoreText.Headline size="xs" weight="heavy">
          {'Reschedule Site Visit'}
        </CoreText.Headline>
        <CoreText.Paragraph weight="light">
          {"Select from the homeowner's availability below to reschedule your site visit for this project."}
        </CoreText.Paragraph>
      </header>
      <HouseholdDetails className={styles.householdDetails} property={calendar.property} screen="unscheduled" />
      <CoreText.Paragraph className={styles.availabilityTitle} color="disabledBlack">
        {'Homeowner availability'}
      </CoreText.Paragraph>
      <div className={styles.availabilityContainer}>
        <AvailabilitySelection
          calendar={calendar}
          onTimeSelected={handleTimeSelected}
          submitting={submitting}
          timer={timer}
        />
        <div className={styles.buttons}>
          <CoreButton className="" text="Back" kind="secondary" disabled={submitting} onClick={handleClose} />
        </div>
      </div>

      {scheduleError && (
        <FailureModal
          title={`Failed to ${time ? 'Schedule' : 'Decline'} Site Visit`}
          onClose={handleScheduleErrorClose}
        >
          <p>
            {`An error occurred while attempting to reschedule your site visit. Please try again later, or contact your advisor for more help.`}
          </p>
        </FailureModal>
      )}
      {timeUnavailableModal && (
        <FailureModal title="Time slot no longer available" onClose={handleTimeUnavailableModalClose}>
          <p>{`The time you have selected is no longer available. Please pick a different time.`}</p>
        </FailureModal>
      )}
    </div>
  )
}

export default Unscheduled
